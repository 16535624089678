import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import research from '../images/research.jpg';
import design from '../images/design.jpg';
import develop from '../images/database.jpg';

const useStyles = makeStyles((theme) => ({
  card: {
    fontSize: '1rem',
    width: '100%',
    padding: '1rem',
    height: '20rem',
    textAlign: 'center',
    borderRadius: '3px',
    boxShadow: '0 1.5rem 4rem rgba(0,0,0,0.15)',
    display: 'inline-block',
    boxSizing: 'border-box',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all 0.5s',
    '& h4': {
      ...theme.typography.header,
      color: theme.palette.common.white,
      marginBottom: 0,
      transition: 'all 0.5s',
      [theme.breakpoints.down('md')]: {
        fontSize: '2.5rem',
      },
    },
    '& p': {
      visibility: 'hidden',
      ...theme.typography.cardText,
      color: theme.palette.common.white,
    },
    '&:hover': {
      transform: 'scale(1.01) translateY(-1.03rem)',
      '& > p': {
        visibility: 'visible',
        animation: 'moveInBottomNoSnap 0.5s ease-out',
      },
      '& > h4': {
        transform: 'translateY(-1.4rem)',
      },
    },
  },
  cardone: {
    backgroundImage: `linear-gradient(to bottom,rgba(0,173,183,.5), rgba(0,173,183,.4)), url('${research}')`,
    '&:hover': {
      backgroundImage: `linear-gradient(to bottom,rgba(0,173,183,1), rgba(0,173,183,.85)), url('${research}')`,
    },
  },
  cardtwo: {
    backgroundImage: `linear-gradient(to bottom,rgba(0,173,183,.5), rgba(0,173,183,.4)), url('${design}')`,
    '&:hover': {
      backgroundImage: `linear-gradient(to bottom,rgba(0,173,183,1), rgba(0,173,183,.85)), url('${design}')`,
    },
  },
  cardthree: {
    backgroundImage: `linear-gradient(to bottom,rgba(0,173,183,.5), rgba(0,173,183,.4)), url('${develop}')`,
    '&:hover': {
      backgroundImage: `linear-gradient(to bottom,rgba(0,173,183,1), rgba(0,173,183,.85)), url('${develop}')`,
    },
  },
}));

const ServiceCard = ({ heading, body, index }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.card} ${classes[`card${index}`]}`}>
      <h4>{heading}</h4>
      <p className={classes.info}>{body}</p>
    </div>
  );
};

export default ServiceCard;
