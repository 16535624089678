import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import keyboard from '../images/christian-wiediger-WkfDrhxDMC8-unsplash.jpg';

const useStyles = makeStyles((theme) => ({
  hero: {
    height: '85vh',
    backgroundImage: `linear-gradient(to bottom,rgba(34,40,49,1), rgba(34,40,49,.85)), url('${keyboard}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '-2rem',
    clipPath: 'polygon(0 0,100% 0, 100% 79vh, 0 100%)',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '70vh',
      clipPath: 'polygon(0 0,100% 0, 100% 65vh, 0 100%)',
    },
  },
  textBox: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  heading: {
    color: theme.palette.common.white,
    backfaceVisibility: 'hidden',
    marginBottom: '6rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '2rem',
    },
  },
  headingMain: {
    display: 'block',
    textTransform: 'uppercase',
    ...theme.typography.header,
    animation: 'moveInLeft 0.5s ease-out',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  headingSub: {
    display: 'block',
    textTransform: 'uppercase',
    ...theme.typography.subheader,
    animation: 'moveInRight 0.5s ease-out',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      letterSpacing: '.25rem',
    },
  },
  button: {
    padding: '1rem 4rem',
    ...theme.typography.button,
    animation: 'moveInBottom 0.5s ease-out',
  },
}));

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.hero}>
      <div className={classes.textBox}>
        <h1 className={classes.heading}>
          <span className={`${classes.heading} ${classes.headingMain}`}>
            Responsive, Creative, Simple
          </span>
          <span className={`${classes.heading} ${classes.headingSub}`}>
            Web and data solutions to fit your users' tastes and your needs
          </span>
        </h1>
        <Button
          component={Link}
          to="/about"
          variant="outlined"
          color="secondary"
          className={classes.button}
        >
          About Me
        </Button>
      </div>
    </div>
  );
};

export default Hero;
