import React from 'react';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import codePic from '../images/irvan-smith-5eBW5GomfhY-unsplash.jpg';
import ServiceCard from './ServiceCard';

const useStyles = makeStyles((theme) => ({
  serviceSection: {
    padding: '10rem 0',
    margin: '8rem 0',
    backgroundImage: `linear-gradient(to bottom,rgba(34,40,49,1), rgba(34,40,49,.85)), url('${codePic}')`,
    backgroundSize: 'cover',
    transform: 'skewY(-3deg)',
    '&>*': {
      transform: 'skewY(3deg)',
    },
    [theme.breakpoints.down('md')]: {
      margin: '6rem 0',
    },
  },
  container: {
    textAlign: 'center',
  },
  grid: {
    marginBottom: '4rem',
  },
  learnMore: {
    padding: '1rem 4rem',
    ...theme.typography.button,
  },
}));

const ServiceSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.serviceSection}>
      <Container className={classes.container}>
        <Grid
          container
          justify="space-evenly"
          spacing={3}
          className={classes.grid}
        >
          <Grid item md={3} sm={12}>
            <ServiceCard
              heading="Research"
              body="Let's get together and brainstorm what you will need"
              index="one"
            />
          </Grid>
          <Grid item md={3} sm={12}>
            <ServiceCard
              heading="Design"
              body="Put the paint to the canvas and flesh out a crisp and clean design"
              index="two"
            />
          </Grid>
          <Grid item md={3} sm={12}>
            <ServiceCard
              heading="Develop"
              body="Add all the bells and whistles to make your website function at the highest levels"
              index="three"
            />
          </Grid>
        </Grid>
        <Button
          component={Link}
          to="/services"
          variant="outlined"
          color="secondary"
          className={classes.learnMore}
        >
          Learn More
        </Button>
      </Container>
    </div>
  );
};

export default ServiceSection;
