import React, { useState } from 'react';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FullDialog from './FullDialog';
import ProjectBody from './ProjectBody';

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: '100%',
  },
  projectTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '2rem',
    color: '#536177',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  projectContainer: {
    width: '100%',
  },
  toolCard: {
    width: '100%',
    padding: '2rem 3rem',
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 1.5rem',
    },
  },
  toolContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolText: {
    ...theme.typography.body,
    textAlign: 'left',
    fontWeight: 700,
    marginBlock: 0,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  learnMoreButton: {
    marginTop: '1.5rem',
    padding: '0.5rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 1rem',
    },
  },
}));

const ProjectTest = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <React.Fragment>
      <Container>
        <h2 className={classes.projectTitle}>{props.node.title}</h2>
        <Grid
          container
          justify="space-between"
          className={classes.projectContainer}
        >
          <Grid item sm={7} md={8}>
            <div className={classes.thumbnail}>
              <Img fluid={props.node.mainImage.fluid} />
            </div>
          </Grid>
          <Grid item md={3} sm={4}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item className={classes.projectContainer}>
                <Typography variant="overline" className={classes.builtWith}>
                  Built with:{' '}
                </Typography>
                <Paper elevation={3} className={classes.toolCard}>
                  <Grid container>
                    {props.node.builtWith.map((tool) => {
                      return (
                        <Grid item sm={6}>
                          <p className={classes.toolText}>{tool}</p>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item>
                <Button
                  disableRipple
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpen}
                  className={classes.learnMoreButton}
                >
                  Read More
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <FullDialog
        open={open}
        setOpen={setOpen}
        // website={props.website}
        // sourceCode={props.sourceCode}
        // apiSourceCode={props.apiSourceCode}
      >
        <ProjectBody
          data={props.node.body}
          title={props.node.title}
          website={props.node.websiteUrl}
          repo={props.node.repoUrl}
        />
      </FullDialog>
    </React.Fragment>
  );
};

export default ProjectTest;
