import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Hero from '../components/Hero';
import Mission from '../components/Mission';
import ProjectTest from '../components/ProjectTest';
import ProjectMobile from '../components/ProjectMobile';
import ServiceSection from '../components/ServiceSection';
import logo from '../images/logo.svg';
import favicon from '../images/favicon.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.header,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  container: {
    paddingBlockEnd: '18rem',
    [theme.breakpoints.down('md')]: {
      paddingBlockEnd: '10rem',
    },
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const graph = useStaticQuery(graphql`
    query {
      contentfulMission {
        missionStatement {
          raw
        }
        image {
          file {
            url
          }
        }
      }
      allContentfulProject(sort: { fields: createdAt }) {
        edges {
          node {
            title
            builtWith
            websiteUrl
            repoUrl

            body {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  file {
                    fileName
                    url
                    contentType
                  }
                  fluid(maxWidth: 500, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
            }
            mainImage {
              file {
                url
                fileName
              }
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);
  const projectData = graph.allContentfulProject.edges;
  const missionData = graph.contentfulMission;
  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
        <meta property="og:title" content="Nabhas Alakkat | Portfolio" />
        <meta property="og:description" content="my web developer portfolio" />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://alakkat.com/" />
        <title>Nabhas Alakkat | Portfolio</title>
        <link rel="canonical" href="https://alakkat.com/" />
      </Helmet>
      <Hero />
      <Mission
        text={missionData.missionStatement}
        img={missionData.image.file}
      />
      <h1 className={classes.header}>Featured Project</h1>
      {projectData.slice(0, 1).map((project, i) => {
        return matches ? (
          <ProjectMobile key={i} {...project} />
        ) : (
          <ProjectTest key={i} {...project} />
        );
      })}
      <ServiceSection />
      {projectData.slice(1).map((project, i) => {
        return matches ? (
          <ProjectMobile key={i} {...project} />
        ) : (
          <ProjectTest key={i} {...project} />
        );
      })}
    </div>
  );
};

export default LandingPage;
