import React, { useState } from 'react';
import Img from 'gatsby-image';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import FullDialog from './FullDialog';
import ProjectBody from './ProjectBody';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '1rem',
    paddingBottom: '1rem',
  },
  projectTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1.75rem',
    margin: 0,
    padding: '1rem',
    textAlign: 'center',
  },
  thumbnail: {
    width: '100%',
  },
  toolContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: '1rem',
  },
  toolLogo: {
    height: '1.5rem',
    width: '1.5rem',
  },
  toolText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.secondary.main,
    fontSize: '1rem',
  },
  learnMoreButton: {
    width: '90%',
    display: 'block',
    margin: '1rem auto',
  },
}));

const ProjectMobile = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <Paper className={classes.container}>
      <h2 className={classes.projectTitle}>{props.node.title}</h2>
      <Img fluid={props.node.mainImage.fluid} className={classes.thumbnail} />
      <Grid container justify="space-evenly">
        {props.node.builtWith.map((tool) => {
          return (
            <Grid item className={classes.toolContainer}>
              <h2 className={classes.toolText}>{tool}</h2>
            </Grid>
          );
        })}
      </Grid>
      <Button
        disableRipple
        className={classes.learnMoreButton}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Read More
      </Button>
      <FullDialog open={open} setOpen={setOpen}>
        <ProjectBody
          data={props.node.body}
          title={props.node.title}
          website={props.node.websiteUrl}
          repo={props.node.repoUrl}
        />
      </FullDialog>
    </Paper>
  );
};

export default ProjectMobile;
