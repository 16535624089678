import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

//Material-UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  body: {
    ...theme.typography.body,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  header: {
    ...theme.typography.header,
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  headerTwo: {
    ...theme.typography.headerThree,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
  },
  imageWrapper: {
    width: '100%',
  },
  image: {
    width: '100%',
  },
  missionWrapper: {
    marginBottom: '4rem',
  },
  paper: {
    padding: '2rem',
    marginBlock: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginBlock: '4rem',
    },
  },
  bold: {
    fontWeight: 700,
    fontSize: '1.4rem',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
  },
}));

const Bold = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.bold}>{children}</span>;
};
const Italics = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.italics}>{children}</span>;
};
const Text = ({ children }) => {
  const classes = useStyles();
  return <p className={classes.body}>{children}</p>;
};
const Heading = ({ children }) => {
  const classes = useStyles();
  return <h2 className={classes.header}>{children}</h2>;
};
const HeadingTwo = ({ children }) => {
  const classes = useStyles();
  return <h3 className={classes.headerTwo}>{children}</h3>;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italics>{text}</Italics>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content.value !== '' && <Text>{children}</Text>,
    [BLOCKS.HEADING_1]: (node, children) => <Heading>{children}</Heading>,
    [BLOCKS.HEADING_2]: (node, children) => <HeadingTwo>{children}</HeadingTwo>,
  },
};

const Mission = ({ text, img }) => {
  const classes = useStyles();
  console.log(img);
  return (
    <div className={classes.missionWrapper}>
      <Container>
        <Paper className={classes.paper}>
          <h2 className={classes.header}>My Mission</h2>
          <Grid container justify="space-between" alignItems="center">
            <Grid item md={6}>
              <div className={classes.richTextWrapper}>
                {text && renderRichText(text, options)}
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={classes.imageWrapper}>
                <img
                  className={classes.image}
                  src={img.url}
                  alt={img.fileName}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default Mission;
